import DashboardOutlinedIcon from '@mui/icons-material/DashboardOutlined'
import FormatListBulletedOutlinedIcon from '@mui/icons-material/FormatListBulletedOutlined'
import HomeOutlinedIcon from '@mui/icons-material/HomeOutlined'
import SpeedOutlinedIcon from '@mui/icons-material/SpeedOutlined'
import SummarizeOutlinedIcon from '@mui/icons-material/SummarizeOutlined'

import config from 'config'

const general = {
  id: 'general',
  title: 'General',
  type: 'group',
  children: [
    {
      id: 'dashboard',
      title: 'Home',
      type: 'item',
      url: '/',
      icon: HomeOutlinedIcon,
      breadcrumbs: false,
    },
    {
      id: 'productionDashboard',
      title: 'Production Dashboard',
      collapsedTitle: 'Prod Board',
      type: 'item',
      url: `/production-dashboard`,
      icon: DashboardOutlinedIcon,
      breadcrumbs: false,
    },
    {
      id: 'jobs',
      title: 'Jobs',
      type: 'item',
      url: `${config.cms1Url}/service-jobs`,
      icon: FormatListBulletedOutlinedIcon,
      breadcrumbs: false,
      external: true,
      target: true,
    },
    {
      id: 'liveCapacity',
      title: 'Live Capacity',
      type: 'item',
      url: `${config.cms1Url}/liveCapacity`,
      icon: SpeedOutlinedIcon,
      breadcrumbs: false,
      external: true,
      target: true,
    },
    {
      id: 'queries',
      title: 'Queries',
      type: 'item',
      url: `/queries`,
      icon: SummarizeOutlinedIcon,
      breadcrumbs: false,
    },
  ],
}

export default general
